import { Renderer } from "@unseenco/taxi";
import animations from "../utils/animations.js";

export default class CustomRenderer extends Renderer {
	initialLoad() {
		// run after the initial load of the Taxi container
		setTimeout(() => {
			animations();
		}, 300);
	}

	// rest of your methods
	onEnter() {
		// run after the new content has been added to the Taxi container
	}

	onEnterCompleted() {
		// run after the transition.onEnter has fully completed
		animations();
	}

	onLeave() {
		// run before the transition.onLeave method is called
	}

	onLeaveCompleted() {
		// run after the transition.onleave has fully completed
	}
}
