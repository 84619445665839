import { Transition } from "@unseenco/taxi";

const el = document.querySelector("#transition");
const body = document.querySelector("body");

export default class toHome extends Transition {
	/**
	 * Handle the transition leaving the previous page.
	 * @param { { from: HTMLElement, trigger: string|HTMLElement|false, done: function } } props
	 */
	onLeave({ from, trigger, done }) {
		console.log("toHome");
		body.classList.remove("bg-black");
		el.classList.add("bg-white");
		el.classList.add("active");
		setTimeout(() => {
			done();
		}, 600);
	}

	/**
	 * Handle the transition entering the next page.
	 * @param { { to: HTMLElement, trigger: string|HTMLElement|false, done: function } } props
	 */
	onEnter({ to, trigger, done }) {
		window.scrollTo(0, 0);
		body.classList.add("bg-white");
		el.classList.remove("active");
		el.classList.remove("bg-white");
		done();
	}
}
