import Swiper from "swiper";
import "swiper/css";

export default function mediaGallery() {
	const carousel = this.$refs.carousel;
	const homeHeader = document.getElementById("homeHeader");
	const homeFooter = document.getElementById("homeFooter");
	const swiper = new Swiper(carousel, {
		slidesPerView: "auto",
		spaceBetween: 0,
		speed: 600,
		grabCursor: true,
	});
	return {
		activeItem: "01",
		init() {
			setTimeout(() => {
				this.setHeight();
			}, 300);

			window.addEventListener("resize", () => {
				this.setHeight();
			});

			// add event listener for user hitting arrow right key
			window.addEventListener("keydown", (e) => {
				if (e.key === "ArrowLeft") {
					this.prev();
				}
				if (e.key === "ArrowRight") {
					this.next();
				}
			});

			swiper.on("slideChange", () => {
				const adjustedIndex = swiper.realIndex + 1;
				this.activeItem = adjustedIndex.toString().padStart(2, "0");
			});
		},
		setHeight() {
			if (window.innerWidth < 640) {
				carousel.style.height = "calc(100vw - 40px)";
				return;
			}
			const headerHeight = homeHeader.offsetHeight;
			const footerHeight = homeFooter.offsetHeight;
			const totalHeight = headerHeight + footerHeight;
			carousel.style.height = `calc(100vh - ${totalHeight}px)`;
		},
		next() {
			swiper.slideNext();
		},
		prev() {
			swiper.slidePrev();
		},
	};
}
