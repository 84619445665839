import { inView, animate } from "motion";

export default function animations() {
	inView("[data-animate]", (info) => {
		animate(
			info.target,
			{
				opacity: [0, 1],
				filter: ["blur(8px)", "blur(0px)"],
				transform: ["translateY(1em)", "translateY(0em)"],
			},
			{
				duration: 0.3,
				delay: 0.3,
				easing: "cubic-bezier(0.4, 0, 0.2, 1)",
			}
		);
	});

	inView('[data-animate="delayed"]', (info) => {
		animate(
			info.target,
			{
				opacity: [0, 1],
				filter: ["blur(8px)", "blur(0px)"],
				transform: ["translateY(1em)", "translateY(0em)"],
			},
			{
				duration: 0.3,
				delay: 0.9,
				easing: "cubic-bezier(0.4, 0, 0.2, 1)",
			}
		);
	});

	inView(
		'[data-animate="rich-text"] p, [data-animate="rich-text"] a, [data-animate="rich-text"] ul, [data-animate="rich-text"] ol, [data-animate="rich-text"] li, [data-animate="rich-text"] blockquote, [data-animate="rich-text"] h1, [data-animate="rich-text"] h2, [data-animate="rich-text"] h3',
		(info) => {
			animate(
				info.target,
				{
					opacity: [0, 1],
					filter: ["blur(8px)", "blur(0px)"],
					transform: ["translateY(1em)", "translateY(0em)"],
				},
				{
					duration: 0.3,
					delay: 0.3,
					easing: "cubic-bezier(0.4, 0, 0.2, 1)",
				}
			);
		}
	);
}
